@import 'Colours';

.link {
  font-weight: 500;
  text-decoration: none;
  color: $paysimply-blue;

  &:hover {
    color: $paysimply-blue-link-hover;
  }

  @media print {
    &:global(.printUrl.printing) {
      color: unset;
      font-weight: unset;
      &::after {
        content: " (" attr(href) ")"; // Prints the URL after the link text
        word-break: break-all;
      }
    }
  }
}
