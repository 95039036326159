@import 'Colours';
@import 'Utils';


.footer {
  background-color: $background-dark-grey;
  font-weight: 400;
  color: $paysimply-grey-text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;

  p {
    margin: 0;
  }
}

.footerContainer {
  padding: 24px 26px 12px 26px;
  max-width: 1500px;

  @media (max-width: 360px) {
    padding: 24px 10px 12px 10px;
  }
}

.lightLink {
  color: $paysimply-grey-links;
  font-size: 1rem;
  font-weight: 400;

  &:hover {
    color: $paysimply-white-text;
  }
}

.footerDescriptionContainer {
  border-bottom: #ffffff55 1px solid;
  padding-bottom: 14px;

  p {
    margin-bottom: 8px;
    font-size: 0.9rem;
  }
}

.termsContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 0.9rem;
  a {
    margin: 8px 4px;
    &:first-child {
      margin-left: 0;
    }
    @extend .lightLink;
    font-size: 0.9rem;
    font-weight: 500;
  }
  p {
    margin-bottom: 2px;
  }
}

.addressContainer {
  text-align: right;
  p {
    margin: 0;
  }

  .highlightedText {
    font-weight: 500;
    color: #FFFFFF;
  }

  @media (max-width: $mobile-viewport-breakpoint) {
    text-align: left;
    margin-top: 8px;
  }
}

.footerDescription.footerDescription {
  padding-right: 24px;
  @media (max-width: $mobile-viewport-breakpoint) {
    padding-right: 0;
    order: 2;
    margin-top: 20px;
  }
}

.footerLinkContainer {
  padding: 28px 0;
  @media (max-width: $mobile-viewport-breakpoint) {
    padding: 28px 0 24px 0;
  }
}

.linkGroup.linkGroup {
  padding-right: 1.5rem;
  @media (max-width: $mobile-viewport-breakpoint) {
    padding-right: 0.5rem;
    margin-bottom: 12px;
  }
}

.linkGroupTitle {
  font-size: 1.2em;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 16px;
}



.footerLink {
  margin: 18px 0;
  a {
    @extend .lightLink;
  }
  svg {
    margin-right: 12px;
    color: $paysimply-grey-links;
  }
}

.mobileApp {
  p {
    font-size: 16px;
    font-weight: 400;
    color: $paysimply-grey-text;
    display: inline;
  }
  a {
    @extend .lightLink;
    color: #FFFFFFCC;
  }
  
}

.appLinksContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 16px;
  & > :first-child {
    margin: 0 16px 16px 0;
  }
}

.boldLink {
  margin-bottom: 0;
  a {
    @extend .lightLink;
    color: $paysimply-grey-links;
    font-weight: 500;
    margin: 8px 0 !important;
  }
}

.footerThirdPartyDisclaimers {
  background-color: $background-black;
  padding: 12px 0;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 400;
  color: $paysimply-grey-text;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: $mobile-viewport-breakpoint) {
    text-align: left;
  }
  p {
    margin: 0;
  }
}

.footerDisclaimerContainer {
  padding: 4px 26px;
  max-width: 1500px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 0.7rem;

  @media (max-width: 360px) {
    padding: 4px 10px;
  }
}

.dropdownLanguage {
  display: flex;
  justify-content: flex-end;
  @media (max-width: $mobile-viewport-breakpoint) {
    justify-content: flex-start;
  }

  a {
    &:hover{
      background-color: #EAEAEA;
    }
    &:active {
      background-color: $paysimply-red;
    }
  }
  button {
    background-color: transparent;
    color: white;
    border: 1px solid #FFFFFFCC !important;
    padding-inline: 0 4px;
    font-size: 1.2rem;
    font-weight: 400;

    @media (max-width: $mobile-viewport-breakpoint) {
      width: 100%;
      .dropDownIcon {
        margin-left: auto;
      }
    }

    &:hover, &:focus, &:active, &:visited, &:focus-visible, &:first-child:active {
      background-color: transparent;
      border: 1px solid white;
    }
    &::after {
      display: none;
    }
  }
}

.dropDownMenu {
  min-width: 8.4rem;
  @media (max-width: $mobile-viewport-breakpoint) {
    width: 100%;
  }
}

.dropdownDisplay {
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  color: white;
  align-items: center;
  padding: 0 4px;

  & > * {
    margin-inline: 6px;
  }
  @media (max-width: $mobile-viewport-breakpoint) {
    justify-content: flex-start;
  }
  
}

