$paysimply-red: #bb1b20;

$paysimply-blue: #2b4e7b;
$paysimply-blue-link-hover: #102540;
$paysimply-blue-hover: #26446b;

$paysimply-primary-background: #ffffff;
$paysimply-secondary-background: #f8f9fa;

$paysimply-white-text: #ffffff;
$paysimply-grey-text: #ffffff99;
$paysimply-grey-links: #FFFFFFCC;

$background-dark-grey: #232222;
$background-black: #0d0d0d;

$paysimply-primary-text: #000000;

$paysimply-input-items-background: #e9ecef;
$paysimply-input-items-border-color: #ced4da;
$paysimply-lite-text: #000000CC;

$state-success: #28a745;
$state-danger: #dc3545;
$state-info: #00AEEF;
