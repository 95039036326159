@import 'Colours';
@import 'Utils';

.heroSection {
  background-color: #D9E4F2;
  padding-top: 20px;
  b {
    font-weight: 500;
  }
}

.highlightChip {
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: #FFFFFF;
  padding: 6px 8px;
  border-radius: 8px;
  border: 1px solid #C7C7C7;
  line-height: 1.3rem;

  & > img {
    margin-right: 10px;
  }

  @media (max-width: $mobile-viewport-breakpoint) {
    font-size: 1rem;
  }
}

.heroTitle {
  font-size: 3.5rem;
  font-weight: 400;
  margin: 0;
  @media (max-width: 1200px) {
    font-size: 2.4rem;
  }
}

.heroTitleNoWrap {
    word-break: keep-all;
    white-space: nowrap;
}
.heroFeatures {
  margin: 12px 0;

  & > div {
    margin-bottom: 12px;
  }

  span {
    margin-right: 24px;
    display: inline-block;
    @media (max-width: $mobile-viewport-breakpoint) {
      display: block;
      margin-bottom: 12px;
    }
  }

  svg {
    margin-right: 8px;
  }
}

.searchFieldTitle {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0;
}
.searchFieldContainer {
  display: flex;
  align-items: center;
}

.searchField {
  border: unset;
  box-shadow: unset;
  border-radius: 0;
  border-bottom: 1px solid #A9A9A9;
  margin-right: 4px;
  &:focus {
    border-radius: 8px;
  }
}

.searchButton{
  border: none !important;
  padding: 0 0 !important;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 2px;
  &:hover { 
    background-color: transparent !important;
    color: $paysimply-blue-link-hover !important;
  }

  &:focus {
    box-shadow: none !important;
    outline: 1px solid $paysimply-blue;
  }
}

.quickSearchBoxCTA {
  background: $paysimply-secondary-background;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.ctaIcon {
  width: 24px;
  margin-right: 16px;
}

.paymentOptionsSection {
  background: #f8f9fA;
  border-bottom: 1px solid #00000022;
  display: flex;
  justify-content: center;
}

.paymentOptionsList {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.paymentOptionsDisclaimer {
  background-color: #fff;
  margin: 0;
  padding: 8px 0;
  font-weight: 300;
  font-size: 0.8rem;
  color: #000000e6;
  text-align: center;
}

.paymentOptionCard {
  background-color: transparent;
  padding: 8px 6px !important;
  border-right: 1px solid #00000022;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  &:last-of-type {
    border-right: unset;
  }
  
  @media (max-width: $mobile-breakpoint-payment-process) {
    &:nth-of-type(even) {
      border-right: unset;
    }
    
    &:nth-of-type(1), &:nth-of-type(2) {
      border-bottom: 1px solid #00000022;
    }
  }

}

.poImagesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-right: 8px;
}


.paymentOptionDescription {
  font-size: 0.8rem;
  margin: 4px 0;
  text-align: center;
}

.feeChip {
  font-size: 0.7rem;
  padding: 4px 10px;
  border-radius: 20px;
  border: 1px solid #0000004D;
}

.quickSearchBox {
  position: relative;
  z-index: 1;
}

.heroImageContainer {
  position: relative;
  padding-bottom: 70px;
  & > img {
    z-index: 0;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  @media (max-width: $mobile-breakpoint-payment-process) {
    display: none;
  }
}
.heroImageContainerCRA {
  @extend .heroImageContainer;
  padding-bottom: 120px;
}

.sectionContainer {
  background: $paysimply-primary-background;
  padding: 34px 0;
  display: flex;
  justify-content: center;
}

.mobileAppContentContainer {
  background: $paysimply-secondary-background;
  padding: 36px 16px 16px 16px;
  border-radius: 36px;
}

.mobileAppContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
  h2 {
    font-size: 2rem;
    font-weight: 400;
  }

  @media (max-width: $mobile-viewport-breakpoint) {
    justify-content: center;
  }
}

.mobileAppScreenShot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 68px !important;

  @media (max-width: $mobile-viewport-breakpoint) {
    justify-content: center;
    padding-right: 0 !important;
  }
}

.heroMobileAppImageContainer {
  @extend .mobileAppScreenShot;
  justify-content: center;
  align-items: center;
  padding-right: 0 !important;
}

.mobileAppImage {
  object-fit: contain;
  width: auto;
  height: 380px;
  @media (max-width: $mobile-breakpoint-payment-process) {
    height: 300px;
  }
}

.heroMobileAppImage {
  object-fit: contain;
  width: 100%;
  height: auto;
  max-width: 440px;
  @media (max-width: $mobile-breakpoint-payment-process) {
    margin-bottom: 24px;
  }
}

.mobileAppDownloadContainer {
  display: flex;
  flex-direction: row;
  @media (max-width: 360px) {
    flex-direction: column;
  }
  & > div {
    margin-right: 8px;
  }
}

.mobileAppText {
  & > * {
    margin: 10px 0;
  }
  svg {
    margin-right: 22px;
  }
  margin-bottom: 32px;
}

.ratingText {
  font-weight: 400;
  font-size: 0.7rem;
  margin-top: -6px;
}

.paymentSourceTitleContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.paymentSourceTitle {
  font-weight: 400;
  display: inline;
  margin-right: 12px;
}

.awardsContainer {
  display: flex;
  flex-direction: row;

  @media (max-width: $mobile-viewport-breakpoint) {
    flex-direction: column;
  }

  & > div {
    margin-right: 46px;
  }

  p {
    font-weight: 300;
  }
}

.slideShowContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.headingContainer {
  text-align: center;
  h2 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 12px;
  }
  b {
    font-weight: 500;
  }
  p {
    margin-bottom: 12px;
  }
}

.testimonialsContainer {
  width: 100%;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-top: 20px;
  gap: 20px;
  scrollbar-width: none;
}

.testimonialsContainer::-webkit-scrollbar {
  display: none;
}

.testimonialCardContainer {
  min-width: 400px;
  @media (max-width: $mobile-viewport-breakpoint) {
    min-width: 300px;
  }
}

.slideShowControlsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $mobile-viewport-breakpoint) {
    justify-content: flex-start;
  }
}

.slideShowButton {
  padding: 8px 16px;
  border: 1px solid #0000004D;
  background-color: #fff
}

.slideShowRightButton {
  @extend .slideShowButton;
  border-left: none;
  border-radius: 0 30px 30px 0;
}

.slideShowLeftButton {
  @extend .slideShowButton;
  border-right: none;
  border-radius: 30px 0 0 30px;
}

.testimonialsViewMoreLink {
  position: relative;
  & > a {
    position: absolute;
    left: 16px;
    bottom: 0;
    transform: translate(0, 50%);
    min-width: 200px;
  }
}

.quickLinksContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #0000004d;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  min-width: 600px;
  z-index: 3;

  @media (max-width: $mobile-viewport-breakpoint) {
    min-width: unset;
  }

  & > h2 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .quickLinkBtn {
    padding: 5px 10px;
    justify-content: space-between;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: left;

    @media (max-width: 360px) {
      font-size: 1rem;
    }
  }
}

.quickLinksButtonContainer {
  display: flex;
  justify-content: space-between;

  @media (max-width: $mobile-viewport-breakpoint) {
    flex-direction: column;

    & > :first-child {
      margin-bottom: 10px;
    }
  }
}

.importSectionContainer {
  @extend .mobileAppContentContainer;
  padding: 36px 16px;
}

.importSectionImages {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 75%;

  @media (max-width: $mobile-viewport-breakpoint) {
    flex-direction: column;
    width: 100%;
  }
}

.importSectionImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.importSectionImage {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.remittanceImportImage {
  @extend .importSectionImage;
  max-width: 480px;
}

.importedProfileImage {
  @extend .importSectionImage;
  max-width: 200px;
}

.arrowContainer {
  margin: 12px;
  @media (max-width: $mobile-viewport-breakpoint) {
    transform: rotate(90deg) scale(0.6);
  }
}