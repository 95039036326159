.headingRow {
  margin-top: 36px !important;
  margin-bottom: 14px !important;

  h1 {
    font-size: 2rem;
  }
  b {
    font-weight: 500;
  }
}

.testimonialsGrid {
  margin-bottom: 36px;
  column-gap: 1.25rem;
  column-width: 25rem;
}

.testimonialCardContainer {
  overflow-y: hidden;
  margin-bottom: 16px;
}

.testimonialCardBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;

  b {
    font-weight: 500;
  }
}
.ratingContainer {
  display: flex;
  justify-content: flex-start;
}

.ratingContainer {
  margin-bottom: 8px;
}

.testimonialText {
  margin: unset;
}

.testimonialUser {
  margin: unset;
  margin-top: 8px;
  text-align: right;
  font-weight: 300;
  font-size: 0.9rem;
}
