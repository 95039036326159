@import 'Colours';
@import 'Utils';

.nav {
  width: 100%;
  --link-y-padding: 0.5em;
  --link-x-padding: 1.5em;
  --link-height: 1.5em;
  --navbar-height: 3.5rem;
  --link-y-margin: 0.5em;
  background-color: $paysimply-red;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  height: var(--navbar-height);
  outline: none;

  @media print {
    position: static;
  }
}


.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  display: block;
  aspect-ratio: 100 / 30;
  height: calc(var(--navbar-height) - 20px);
  position: relative;
  margin: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  @media (max-width: $mobile-breakpoint-payment-process) {
    justify-content: center;
    margin: 10px auto 10px auto;
  }
}
.paymentInfoButton {
  display: block;
  position: relative;

  button {
    font-size: 0.9rem;
    height: 2.4em;
    border: none;
    padding: 0 4px;
    z-index: 15;
    &:hover, &:focus{
      color: $paysimply-primary-background !important;
      border: none !important;
      background-color: transparent !important;
    }
  }
}
.navContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: height 0.5s ease;
}

.navLink.navLink {
  color: white;
  padding: var(--link-y-padding) var(--link-x-padding);
  position: relative;
  text-align: center;
  text-decoration: none !important;
  font-size: 1rem;
  font-weight: 400;
  &:hover, &:focus{
    color: white;
  }

  @media (max-width: $mobile-breakpoint-payment-process) {
    padding: var(--link-y-padding)
  }
}

.menuToggle {
  display: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  
}
.paymentInfoButton {
  @extend .menuToggle
}
.hamburgerMenu.hamburgerMenu.hamburgerMenu {
  min-height: 2.4em;
  font-size: 0.9rem;
  color: $paysimply-primary-background;
  border-color: $paysimply-primary-background;
  border-width: 1px;
  background-color: transparent;;
  padding: 6px 16px;
  &:hover, &:focus{
    color: $paysimply-primary-background;
    border-color: $paysimply-primary-background;
    background-color: transparent;;
  }
}

.searchContainer {
  display: flex;
  align-items: center;
  min-width: 300px;

  .searchIcon {
    padding-inline: 0.5em;
    margin-left: -2em;
    z-index: 1;
    color: $paysimply-blue;
    &:hover, &:focus{
      color: $paysimply-blue;
    }
  }
}

// Making the search box wider to account for the text only zoom.
.wideSearchContainer {
  @extend .searchContainer;
  @media (min-width: 1025px) {  
    min-width: 400px;
  }
}

.searchInput {
  height: 40px;
}


@media (max-width: $mobile-breakpoint-payment-process) {
  .nav {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .searchContainer {
    padding: 0.5rem 0;
  }
  .menuToggle {
    position: absolute;
    left: 1rem;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .paymentInfoButton {
    @extend .menuToggle;
    left: unset;
    right: 1rem;
  }

  .navContentContainer {
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    justify-content: flex-start;
    visibility: hidden;
    height: 0;
    a,
    button {
      padding: var(--link-y-padding);
    }
  }

  .navContentContainer.show {
    visibility: visible;
    height: calc(3 * (1 * (var(--link-height) + 2 * var(--link-y-padding) + 2 * var(--link-y-margin))));
  }

}

.dropdownDisplay {
  border: none;
  background-color: transparent;
  font-weight: 400;
  & > * {
    padding: 0 5px 0 20px;

    @media (max-width: $mobile-breakpoint-payment-process) {
      padding: 0 5px 0 0;
    }
  }
  &:hover, &:focus, &:active, &:focus-visible {
    background-color: transparent;
    border: none;
  }
}

.dropDown {
  @media (max-width: $mobile-breakpoint-payment-process) {
    margin-top: 8px;
  }
}
.dropDownItems {
  width: fit-content;
  margin-top: -2px;
  @media (max-width: $mobile-breakpoint-payment-process) {
    padding: 8px 8px;
  }
  // To adjust for the smallest screen sizes
  @media (max-width: 360px) {
    padding: 8px 0;
  }

  a {
    color: black;

    &:hover, &:focus, &:active, &:focus-visible {
      background-color: $paysimply-red;
      color: white;
    }
  }
}